import React, { useEffect } from "react";
import Section from "./components/Section";
import { Box } from "@mui/material";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const App: React.FC = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".section-container");
    sections.forEach((section) => {
      section.classList.add("fadeIn");
    });
  }, []);

  return (
    <Box className="app-container snap-y snap-mandatory overflow-y-scroll h-screen text-slate-100">
      <Section id="home" title="">
        <h1 className="text-6xl text-center">BEHRAD FARAHANI</h1>
        <h2 className="subtitle">EST. 2024</h2>
        <div className="scroll-indicator">
          <div className="pill">
            <div className="dot"></div>
          </div>
          <span>Scroll Down</span>
        </div>
      </Section>
      <Section id="about" title="ABOUT">
        <p className="text-center max-w-2xl">
          Specialized in selling and reselling of quality products at an
          affordable price through leading platforms. Check out our stores on{" "}
          <a
            href="https://www.amazon.nl"
            target="_blank"
            rel="noreferrer"
            className="italic-link"
          >
            amazon.nl
          </a>
          ,{" "}
          <a
            href="https://www.bol.com"
            target="_blank"
            rel="noreferrer"
            className="italic-link"
          >
            bol.com
          </a>
          ,{" "}
          <a
            href="https://www.ebay.nl"
            target="_blank"
            rel="noreferrer"
            className="italic-link"
          >
            ebay.nl
          </a>
          , and{" "}
          <a
            href="https://www.etsy.com"
            target="_blank"
            rel="noreferrer"
            className="italic-link"
          >
            etsy.com
          </a>
          , want to find out more...
        </p>
        <div className="logos-container">
          <img
            src={require("./images/bol.png")}
            alt="logo1"
            className="logo logo1"
          />
          <img
            src={require("./images/ebay.png")}
            alt="logo2"
            className="logo logo2"
          />
          <img
            src={require("./images/amazon.png")}
            alt="logo3"
            className="logo logo3"
          />
          <img
            src={require("./images/etsy.png")}
            alt="logo4"
            className="logo logo4"
          />
          <img
            src={require("./images/shopify.png")}
            alt="logo5"
            className="logo logo5"
          />
        </div>
      </Section>
      <Section id="contact" title="CONTACT US">
        <div className="flex flex-col items-center">
          <div className="flex space-x-4 mt-4">
            <a
              href="mailto:sales@behradfarahani.com"
              target="_blank"
              rel="noreferrer"
              className="custom-pill-button"
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              Sales Team
            </a>
            <a
              href="https://www.instagram.com/farahani_behrad/"
              target="_blank"
              rel="noreferrer"
              className="custom-pill-button"
            >
              <FontAwesomeIcon icon={faInstagram} className="mr-2" />
              Check us out
            </a>
          </div>
        </div>
        <footer className="footer mt-auto">
          powered by&nbsp;
          <a
            href="https://codenecting.com"
            target="_blank"
            rel="noreferrer"
            className="footer-link"
          >
            codenecting
          </a>
        </footer>
      </Section>
    </Box>
  );
};

export default App;
