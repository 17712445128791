import React from 'react';

interface SectionProps {
  id: string;
  title: string;
  children?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ id, title, children }) => {
  return (
    <div id={id} className="section-container snap-start min-h-screen flex flex-col justify-center items-center bg-black">
      <h1 className="text-6xl text-slate-100 text-center">{title}</h1>
      {children}
    </div>
  );
}

export default Section;
